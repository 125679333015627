.nav {
  display: flex;
  padding: 50px 30px 0 50px;
  max-width: 550px;
  margin: 0 0 0 auto;
}

.column {
  flex: 1;
}

.column + .column {
  padding-left: 3em;
}

.category {
  margin: 0 2em 2em 0;
}

.category > a {
  text-decoration: none;
}

.desktop { 
  display: none;
}

.category ul {
  margin: .5em 0 1em;
  padding: 0;
}

.category hr {
  border: 0;
  border-top: 1px solid #ddd;
}

.category li {
  list-style-type: none;
}

.category li a {
  display: block;
  padding: .3em .7em .2em;
  border-radius: .3em;
  text-decoration: none;
  margin: .2em -.7em;
  line-height: 1.2em;
}

.category li a x-nowrap {
  white-space: nowrap;
}

.category li.sub a {
  font-size: .95em;
  color: rgba(0,0,0,.55);
}

.category li.highlight h6 {
  font-weight: normal;
  line-height: 1.1em;
  vertical-align: -webkit-baseline-middle;
}

.column li a.active {
  box-shadow: 0 0 0 1px #bca36c;
}

.category li a:hover {
  background: rgba(0,0,0,.04);
}

.category li a:focus {
  outline: none;
  box-shadow: 0 0 0 2px #bca36c !important;
}

.category li a:active {
  background: rgba(0,0,0,.08);
  box-shadow: 0 0 0 2px #bca36c !important;
}

.category li a span {
  display: block;
  color: rgba(0,0,0,.55);
  font-size: .875em;
  padding: 0 0 .4em 0;
}

.category p {
  color: rgba(0,0,0,.55);
  margin: 0 0 .875em;
  font-size: .875em;
}

@media (max-width: 999px) {
  .account { 
    border-radius: .3em;
    margin: -1em -2em 1.5em -1.8em;
    padding: .5em 4em 0.001em 1.8em;
    background: rgba(0,0,0,.03);
    box-shadow: inset 0 2px 8px rgba(0,0,0,.1);
  }

  .account a {
    color: #bca36c;
  }

  .account li a.active {
    box-shadow: 0 0 0 1px #bca36c;
  }
}

@media (max-width: 580px) {
  .nav {
    padding: 50px 0 0 26px;
    flex-direction: column-reverse;
  }
  .column + .column {
    padding-left: 0;
  }
  .account {
    margin-right: -.2em;
    padding-right: 2.2em;
  }
}


@media (min-width: 1000px) {
  .nav {
    max-width: calc(960px + 2em);
    position: relative;
    transform: none;
    overflow: visible;
    margin: 0 auto;
    padding: 0;
    align-items: center;
    justify-content: space-between;
  }
  .nav > a > img {
    margin: 0 .5em;
    height: 32px;
  }
  .column {
    padding: 0;
    display: flex;
    align-items: stretch;
  }
  .column:last-child {
    justify-content: flex-end;
  }

  .column p {
    font-size: .875em;
    margin: 0;
    color: rgba(255,255,255,.6);
  }

  .column h5 {
    font-size: 0.95em;
    text-transform: none;
    color: rgba(255, 255, 255, .8);
    font-weight: normal;
    margin: .2em 0;
  }

  .category {
    position: relative;
    cursor: pointer;
    margin: 0 1em 0;
    height: 4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .column:last-child .category h5 {
    text-align: right;
  }

  .column:last-child .category ul {
    left: unset;
    right: 0;
  }

  .category > a {
    margin: -.2em -.5em;
    padding: .2em .5em;
  }

  .category.highlight h5 {
    position: relative;
    color: #bca36c;
    margin-right: 1em;
    text-shadow: 1px 1px 1px rgba(0,0,0,.2);
  }
  .category.highlight h5:before {
    content: "";
    position: absolute;
    left: -1em;
    right: -1em;
    border-radius: .5em;
    top: -.5em;
    bottom: -.5em;
    background: rgba(255,255,255,.06);
  }

  .category:hover h5,
  .category:focus-within h5,
  .category:active h5 {
    color: rgba(255, 255, 255, 1) !important;
  }

  .category ul {
    position: absolute;
    top: 3.1em;
    left: 0;
    width: 200px;
    background: rgba(255,255,255,.98);
    padding: 0 1.5em;
    height: 0;
    overflow: hidden;
    transform: translateY(-10px) scale(.97);
    opacity: 0;
    transform-origin: 0 0;
    transition: opacity .15s .05s ease-out, transform .1s .05s ease-out;
    border-radius: .5em;
    box-shadow: 0 5px 8px rgba(0, 0, 0, .15);
  }

  .column:last-child .category ul {
    transform-origin: 100% 0;
  }

  .category:hover ul,
  .category:focus-within ul,
  .category:active ul {
    padding: .65em 1.5em;
    height: auto;
    transform: translateY(0px) scale(1);
    opacity: 1;
  }

  .mobile { 
    display: none;
  }

  .desktop { 
    display: block;
  }
}
